import React, { useEffect } from "react"
import Layout from "../components/layout"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Button from "@material-ui/core/Button"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import Divider from "@material-ui/core/Divider"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import SEO from "./seo"
import Footer from "./footer"
import Logo from "./logo.svg"
import { Link } from "gatsby"
import { useAllArticlesFlat, topicTitles } from "../hooks/articles"
import "katex/dist/katex.min.css"
import templateStyles from "./page-template.module.css"

const ExternalLink = props => {
  if (
    props.href.includes("explorepersonalfinance.com") ||
    props.href.includes("localhost") ||
    props.href[0] === "/"
  ) {
    return <a href={props.href}>{props.children}</a>
  }
  return (
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  )
}

const components = {
  a: ExternalLink,
}

const myStyles = makeStyles(theme => ({
  divider: {
    marginTop: "50px",
  },
  button: {
    marginTop: "15px",
    marginBottom: "15px",
  },
  logo: {
    width: "20px",
    height: "20px",
    marginRight: "10px",
  },
  siteTitle: {
    color: "#586069",
  },
}))

export default function PageTemplate({ data: { mdx } }) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [mdx])
  const classes = myStyles()
  const allArticles = useAllArticlesFlat()
  var index = -1
  for (let i = 0; i < allArticles.length; i++) {
    if (allArticles[i].node.id === mdx.id) {
      index = i
    }
  }

  var prev = null
  var prevTitle = null
  if (index - 1 >= 0) {
    prev = allArticles[index - 1]
    prevTitle = prev.node.frontmatter.button ?? prev.node.frontmatter.title
    if (prev.node.frontmatter.topic !== mdx.frontmatter.topic) {
      prevTitle = "Previous Topic: " + topicTitles[prev.node.frontmatter.topic]
    }
  }
  var next = null
  var nextTitle = null
  if (index + 1 < allArticles.length) {
    next = allArticles[index + 1]
    nextTitle = next.node.frontmatter.button ?? next.node.frontmatter.title
    if (next.node.frontmatter.topic !== mdx.frontmatter.topic) {
      nextTitle = "Next Topic: " + topicTitles[next.node.frontmatter.topic]
    }
  }

  return (
    <Layout headings={mdx.headings}>
      <SEO
        title={mdx.frontmatter.title}
        description={mdx.frontmatter.description}
        image={null}
        pathname={mdx.fields.slug}
        article
      />
      <MDXProvider components={components}>
        <MDXRenderer>{mdx.body}</MDXRenderer>
      </MDXProvider>
      <Divider className={classes.divider} />
      <Grid
        container
        display="flex"
        justify={prev ? "space-between" : "flex-end"}
      >
        {prev && (
          <Button
            startIcon={<ChevronLeftIcon />}
            className={classes.button}
            href={prev.node.fields.slug}
          >
            {prevTitle}
          </Button>
        )}
        {next && (
          <Button
            endIcon={<ChevronRightIcon />}
            className={classes.button}
            href={next.node.fields.slug}
          >
            {nextTitle}
          </Button>
        )}
      </Grid>
      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        description
        topic
      }
      fields {
        slug
      }
      headings {
        depth
        value
      }
    }
  }
`
